import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BODY_2_TH,
  BODY_2_EN,
  CONTENT_CARD,
  CONTENT_CENTER,
  SpaceBetweenContent,
  SPACE_BETWEEN_CONTENT,
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_EN,
  HEADER_TH,
} from '../Layouts/styled/main';
import { images, spacing, color } from '../Layouts/theme';
import Timeline from '../components/about/timeline';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ContentImageLeft from '../components/card-content/content-image-left';
import ContentImageRight from '../components/card-content/content-image-right';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { getSourceImage } from '../utils/image-sharp';

const isBrowser = typeof window !== 'undefined';

const About = ({ data }) => {
  const { t } = useTranslation();
  const [innerWidth, setInnerWidth] = useState<number>(
    isBrowser ? window.innerWidth : 0,
  );



  if (isBrowser) {
    const mediaQuery = '(min-width: 1280px)';
    const mediaQueryList = window.matchMedia(mediaQuery);
    mediaQueryList.addEventListener('change', (event) => {
      setInnerWidth(window.innerWidth);
    });
  }

  const edges = data.allFile.edges;

  return (
    <main className={'main'}>
      {/* //? section 2 */}
      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block">
        <Container fluid style={{ paddingTop: 60, paddingBottom: 50 }}>
          <Row className="justify-content-center">
            <Col xs="10" sm="8" style={{ ...CONTENT_CENTER, maxWidth: 650 }}>
              <div data-aos="fade-up">
                <div
                  style={{
                    ...(i18next?.language === 'th'
                      ? MOBILE_HEADER_TH
                      : MOBILE_HEADER_EN),
                    textAlign: 'center',
                  }}
                >
                  {t('about_ouay_un_title')}
                </div>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('about_ouay_un_description'),
                  }}
                  style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* //? for screen size md++ */}
      <section className="d-none d-md-block">
        <Container fluid style={CONTENT_CARD}>
          <Row className="justify-content-center">
            <Col md="7" style={{ ...CONTENT_CENTER, maxWidth: 655 }}>
              <div data-aos="fade-up">
                <h1
                  style={{
                    ...(i18next?.language === 'th' ? HEADER_TH : HEADER_EN),
                    textAlign: 'center',
                  }}
                >
                  {t('about_ouay_un_title')}
                </h1>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('about_ouay_un_description'),
                  }}
                  style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* //? section 1 */}
      <section>
        <Container fluid>
          <Row className="justify-content-center">
            <MainVideo>
              <VideoBackground>
                <VideoForeground>
                  <IFrame
                    width="100%"
                    src="https://www.youtube.com/embed/V_CRIh_wRTc?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </VideoForeground>
              </VideoBackground>
            </MainVideo>
          </Row>
        </Container>
      </section>
      {/* <section className="hd-size">
        <Container fluid>
          <Row
            className="justify-content-center"
            // style={{ height: '50%', backgroundColor: color.backgroundWhite }}
          >
            <Col xs="auto" data-aos="fade-up" style={{ width: 1080 }}>
              {innerWidth >= 1280 && (
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/V_CRIh_wRTc?autoplay=1"
                  title="YouTube video player"
                  // style="border-radius: 0 110px;"
                  // style={{ borderRadius: '0 110px' }}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* //? section 3 */}
      <div style={{ paddingTop: 60 }} className="d-md-none d-block" />
      <div style={SPACE_BETWEEN_CONTENT} className="d-none d-md-block" />
      <Timeline />

      {/* //? section 4 */}
      <div style={{ paddingTop: 60 }} className="d-md-none d-block" />
      <div style={SPACE_BETWEEN_CONTENT} className="d-none d-md-block" />
      <ContentImageLeft
        img={{
          src: images.aboutImage1,
          alt: 'about-image-1',
        }}
        source={getSourceImage('Ouayun_About_3.jpg', edges)}
        title_name={t('about_we_care_title')}
        content={t('about_we_care_description')}
        content_padding_top={spacing[0]}
      />

      {/* //? section 5 */}
      <SpaceBetweenContent />
      <ContentImageRight
        img={{
          src: images.aboutImage2,
          alt: 'about-image-2',
        }}
        source={getSourceImage('Ouayun_About_1.jpg', edges)}
        title_name={t('about_standard_title')}
        content={t('about_standard_description')}
        content_padding_top={spacing[0]}
      />

      {/* //? section 6 */}
      <SpaceBetweenContent />
      <ContentImageLeft
        img={{ src: images.whyUsImage1, alt: 'why-us-image-1' }}
        source={getSourceImage('Ouayun_About_4.jpg', edges)}
        title_name={t('raw_material')}
        content={t('why_us_material_description')}
        content_padding_top={spacing[0]}
      />

      {/* //? section 7 */}
      {/* <SpaceBetweenContent />
      <ContentImageRight
        img={{ src: images.whyUsImage2, alt: 'why-us-image-2' }}
        source={getSourceImage('Ouayun_About_6.jpg', edges)}
        title_name={t('high_standard_product')}
        content={t('why_us_product_description')}
        content_padding_top={spacing[0]}
      /> */}

      {/* //? section 8 */}
      {/* <SpaceBetweenContent />
      <ContentImageLeft
        img={{ src: images.whyUsImage3, alt: 'why-us-image-3' }}
        source={getSourceImage('Ouayun_About_5.jpg', edges)}
        title_name={t('quality_control')}
        content={t('why_us_quality_description')}
        content_padding_top={spacing[0]}
      /> */}

      <SpaceBetweenContent />
    </main>
  );
};
export default About;

const MainVideo = styled.div`
  width: 1080px;
  height: 609px;
  overflow: hidden;
  position: relative;
  @media (max-width: 992px) {
    height: 560px !important;
  }
  @media (max-width: 768px) {
    height: 430px !important;
  }
  @media (max-width: 576px) {
    height: 360px !important;
  }
`;

const VideoBackground = styled.div`
  background: #000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
`;

const VideoForeground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/about/resize-770x785/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 767, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
