import React, { useEffect, useState, CSSProperties, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  HEADER,
  BODY_2_TH,
  BODY_2_EN,
  TIMELINE_BOX,
  TIMELINE_BOX_LEFT,
  TIMELINE_BOX_RIGHT,
  TIMELINE_DOT,
} from '../../Layouts/styled/main';
import { images, spacing, color } from '../../Layouts/theme';
import timelineItems from './timeline-items';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const CONNECTOR_LEFT: CSSProperties = {
  borderLeft: `2px solid ${color.mainColor5}`,
  zIndex: 99,
};

export const CONNECTOR_RIGHT: CSSProperties = {
  borderRight: `2px solid ${color.mainColor5}`,
  zIndex: 99,
};

const WIDTH = 420;

const Timeline = () => {
  const { t } = useTranslation();
  const [seeMores, setSeeMores] = useState([false, false, false, false]);

  const onChangeSeemore = (index: number) => {
    let see = JSON.parse(JSON.stringify(seeMores));
    see[index] = true;
    setSeeMores(see);
  };

  const HEADER_STYLE: CSSProperties = {
    ...HEADER,
    paddingBottom: spacing[4],
    fontFamily: i18next?.language === 'th' ? 'ChonburiRegular' : 'RozhaRegular',
  };

  const BUTTON: CSSProperties = {
    backgroundColor: 'initial',
    border: 0,
    color: color.textBlack,
    fontSize: 15,
    marginTop: spacing[4],
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  return (
    <Fragment>
      <section className="is-not-hd-size">
        {/* //? for screen size xs-md */}
        <Container fluid className="d-md-none d-block">
          <Row className="justify-content-center">
            <Col xs="10">
              <Container fluid>
                {timelineItems.map((item: any, index: number) => {
                  return (
                    <Row
                      key={`timeline-index-${index}`}
                      style={{
                        borderLeft:
                          timelineItems.length === index + 1
                            ? `2px solid ${color.backgroundPrimary}`
                            : `2px solid ${color.mainColor5}`,
                      }}
                    >
                      <Col
                        xs="auto"
                        style={{ ...TIMELINE_DOT, marginLeft: -6 }}
                      />
                      <Container fluid style={{ marginTop: -20 }}>
                        <Row>
                          <Col style={{ paddingLeft: 25, paddingRight: 0 }}>
                            <div style={HEADER_STYLE}>
                              {item[`title_${i18next.language}`]}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ paddingLeft: 25, paddingRight: 0 }}>
                            <Container
                              fluid
                              style={{
                                marginBottom:
                                  timelineItems.length === index + 1
                                    ? spacing[0]
                                    : spacing[8],
                              }}
                            >
                              <Row>
                                <Col
                                  style={{
                                    ...TIMELINE_BOX,
                                    backgroundColor:
                                      index === 0
                                        ? '#82986f'
                                        : index === 1
                                          ? '#8fae83'
                                          : index === 2
                                            ? '#a8ba9e'
                                            : '#c3d2bb',
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item[`descript_${i18next.language}`],
                                    }}
                                    style={
                                      i18next?.language === 'th'
                                        ? BODY_2_TH
                                        : BODY_2_EN
                                    }
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </Row>
                  );
                })}
              </Container>
            </Col>
          </Row>
        </Container>

        {/* //? for screen size md++ */}
        <Container fluid className="d-none d-md-block">
          {timelineItems.map((item: any, index: number) => {
            if (item.align === 'left') {
              return (
                <Row
                  className="justify-content-md-center"
                  key={`timeline-index-${index}`}
                >
                  <Col
                    md="4"
                    style={{
                      ...TIMELINE_BOX_LEFT,
                      backgroundColor:
                        index === 0
                          ? '#82986f'
                          : index === 1
                            ? '#8fae83'
                            : index === 2
                              ? '#a8ba9e'
                              : '#c3d2bb',
                      marginTop: index === 0 ? 0 : -spacing[7],
                    }}
                    data-aos="fade-right"
                  >
                    <div style={HEADER_STYLE}>
                      {item[`title_${i18next.language}`]}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          seeMores[index] === true
                            ? item[`descript_${i18next.language}`]
                            : item[`abbrev_${i18next.language}`],
                      }}
                      style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
                    />
                    {item.is_more && !seeMores[index] && (
                      <button
                        style={BUTTON}
                        onClick={() => onChangeSeemore(index)}
                      >
                        {t('see_more')}
                      </button>
                    )}
                  </Col>
                  <Col md="auto" style={{ ...TIMELINE_DOT, marginRight: -6 }} />
                  {timelineItems.length === index + 1 ? (
                    <Col md="4" />
                  ) : (
                    <Col md="4" style={CONNECTOR_LEFT} />
                  )}
                </Row>
              );
            }
            return (
              <Row
                className="justify-content-md-center"
                key={`timeline-index-${index}`}
              >
                {timelineItems.length === index + 1 ? (
                  <Col md="4" />
                ) : (
                  <Col md="4" style={CONNECTOR_RIGHT} />
                )}
                <Col md="auto" style={{ ...TIMELINE_DOT, marginLeft: -6 }} />
                <Col
                  md="4"
                  style={{
                    ...TIMELINE_BOX_RIGHT,
                    backgroundColor:
                      index === 0
                        ? '#82986f'
                        : index === 1
                          ? '#8fae83'
                          : index === 2
                            ? '#a8ba9e'
                            : '#c3d2bb',
                    marginTop: index === 0 ? 0 : -spacing[7],
                  }}
                  data-aos="fade-left"
                >
                  <div style={HEADER_STYLE}>
                    {item[`title_${i18next.language}`]}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        seeMores[index] === true
                          ? item[`descript_${i18next.language}`]
                          : item[`abbrev_${i18next.language}`],
                    }}
                    style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
                  />
                  {item.is_more && !seeMores[index] && (
                    <button
                      style={BUTTON}
                      onClick={() => onChangeSeemore(index)}
                    >
                      {t('see_more')}
                    </button>
                  )}
                </Col>
              </Row>
            );
          })}
        </Container>
      </section>

      <section className="hd-size">
        <Container fluid className="d-none d-md-block">
          {timelineItems.map((item: any, index: number) => {
            if (item.align === 'left') {
              return (
                <Row
                  className="justify-content-md-center"
                  key={`timeline-index-${index}`}
                >
                  <div
                    style={{
                      ...TIMELINE_BOX_LEFT,
                      backgroundColor:
                        index === 0
                          ? '#82986f'
                          : index === 1
                            ? '#8fae83'
                            : index === 2
                              ? '#a8ba9e'
                              : '#c3d2bb',
                      marginTop: index === 0 ? 0 : -spacing[7],
                      width: WIDTH,
                    }}
                    data-aos="fade-right"
                  >
                    <div style={HEADER_STYLE}>
                      {item[`title_${i18next.language}`]}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          seeMores[index] === true
                            ? item[`descript_${i18next.language}`]
                            : item[`abbrev_${i18next.language}`],
                      }}
                      style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
                    />
                    {item.is_more && !seeMores[index] && (
                      <button
                        style={BUTTON}
                        onClick={() => onChangeSeemore(index)}
                      >
                        {t('see_more')}
                      </button>
                    )}
                  </div>
                  <div style={{ ...TIMELINE_DOT, marginRight: -6 }} />
                  {timelineItems.length === index + 1 ? (
                    <div style={{ width: WIDTH }} />
                  ) : (
                    <div
                      style={{
                        ...CONNECTOR_LEFT,
                        width: WIDTH,
                      }}
                    />
                  )}
                </Row>
              );
            }
            return (
              <Row
                className="justify-content-md-center"
                key={`timeline-index-${index}`}
              >
                {timelineItems.length === index + 1 ? (
                  <div style={{ width: WIDTH }} />
                ) : (
                  <div style={{ ...CONNECTOR_RIGHT, width: WIDTH }} />
                )}
                <div style={{ ...TIMELINE_DOT, marginLeft: -6 }} />
                <div
                  style={{
                    ...TIMELINE_BOX_RIGHT,
                    backgroundColor:
                      index === 0
                        ? '#82986f'
                        : index === 1
                          ? '#8fae83'
                          : index === 2
                            ? '#a8ba9e'
                            : '#c3d2bb',
                    marginTop: index === 0 ? 0 : -spacing[7],
                    width: WIDTH,
                  }}
                  data-aos="fade-left"
                >
                  <div style={HEADER_STYLE}>
                    {item[`title_${i18next.language}`]}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        seeMores[index] === true
                          ? item[`descript_${i18next.language}`]
                          : item[`abbrev_${i18next.language}`],
                    }}
                    style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
                  />
                  {item.is_more && !seeMores[index] && (
                    <button
                      style={BUTTON}
                      onClick={() => onChangeSeemore(index)}
                    >
                      {t('see_more')}
                    </button>
                  )}
                </div>
              </Row>
            );
          })}
        </Container>
      </section>
    </Fragment>
  );
};
export default Timeline;
